@import '../../styles/vars';

.intro {
  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 70px 0 110px;
    min-height: 100vh;
    background: url('../../img/bg-intro.png') center bottom no-repeat;
    background-size: cover;

    @media screen and (max-width: $screen-lg) {
      background-image: url('../../img/bg-intro-mobile.png');
    }
  }

  &__main {
    min-height: 244px;
    max-width: 800px;

    @media screen and (max-width: $screen-lg) {
      min-height: 0;
    }
  }

  &__content {
    p {
      margin: 0 0 8px;
      font-size: 30px;
      font-weight: 500;

      @media screen and (max-width: $screen-lg) {
        margin-bottom: 2px;
        font-weight: 600;
      }

      @media screen and (max-width: $screen-md) {
        font-size: 20px;
      }
    }
    
    .button,
    .button-appstore {
      @media screen and (max-width: $screen-lg) {
        display: none;
      }
    }
  }

  h1 {
    margin: 0;
  }

  ul {
    margin: 0 0 65px;
    font-size: 22px;
    font-weight: 300;

    @media screen and (max-width: $screen-lg) {
      font-size: 20px;
    }

    li {
      margin: 0 0 12px;

      @media screen and (max-width: $screen-lg) {
        margin-bottom: 0;
      }
    }
  }

  &__footer {
    display: none;

    @media screen and (max-width: $screen-lg) {
      padding: 60px 0;
      display: block;
      text-align: center;
      background-color: $bg-color;
    }
  }

  &--accent {
    .intro__inner {
      @media screen and (max-width: $screen-lg) {
        justify-content: flex-start;
      }
    }

    h1 {
      margin: 0 0 44px;
      color: $primary-color;

      @media screen and (max-width: $screen-md) {
        margin-bottom: 30px;
      }

      span {
        display: block;
        color: $black-color;
        font-weight: 700;
        font-size: 62px;

        @media screen and (max-width: $screen-lg) {
          font-size: inherit;
        }
      }
    }
  }

  &--not-found {
    .intro__footer {
      display: none;
    }

    .intro__inner {
      @media screen and (max-width: $screen-md) {
        justify-content: flex-start;
      }
    }

    .intro__content .button {
      display: inline-block;
    }
  }
}
