@import '../../styles/vars';

.container {
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1200px;

  @media screen and (max-width: $screen-xl) {
    max-width: 960px;
  }

  @media screen and (max-width: $screen-lg) {
    max-width: 640px;
  }
}