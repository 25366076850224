@import '../../styles/vars';

.footer {
  padding: 40px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.55;
  text-align: center;
  background-color: $bg-color;

  &__menu {
    margin: 12px 0 0;
    padding: 0;
    list-style: none;

    li {
      margin: 3px 0;
    }
  }

  span, a {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      color: $accent-color;
    }
  }
}