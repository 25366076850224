$black-color: #000;
$white-color: #fff;

$primary-color: #b17af6;
$secondary-color: #00d4d4;
$accent-color: #ff8562;
$bg-color: #f5f5f5;
$success-color: #94d90a;
$danger-color: #fc4268;
$info-color: #00cbfd;

$screen-xl: 1200px;
$screen-lg: 960px;
$screen-md: 640px;
