@import '../../styles/vars';

.button-appstore {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 8px;

  svg {
    vertical-align: middle;
  }

  &__wrap {
    position: absolute;
    top: 1px;
    left: 8px;
    bottom: 1px;
    right: 8px;
    pointer-events: none;
    transform: translateX(-85px);
    animation-name: flash;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &__effect {
    background: linear-gradient(90deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
    width: 45px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    transform: skewX(-45deg);
  }
}

@keyframes flash {
  30% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}