@import '../../styles/vars';

.advantages {
  position: relative;
  padding: 140px 0 75px;
  overflow: hidden;

  .container {
    @media screen and (max-width: $screen-lg) {
      max-width: 100%;
      padding: 0;
    }
  }

  &__main {
    position: relative;
    z-index: 1;
    padding: 60px 0 0;
    background-color: $info-color;

    @media screen and (max-width: $screen-lg) {
      padding: 0;
    }

    &::before, &::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: 50%;
      width: 120%;
      height: 150px;
      background-color: $info-color;
      border-radius: 48%/50%;

      @media screen and (max-width: $screen-lg) {
        background-color: #02aafe;
      }
    }

    &::before {
      top: 0;
      transform: translate(-50%, -50%);
    }

    &::after {
      bottom: 0;
      transform: translate(-50%, 50%);

      @media screen and (max-width: $screen-lg) {
        display: none;
      }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;

    @media screen and (max-width: $screen-lg) {
      margin: 0;
    }
  }

  &__column {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 20px;

    @media screen and (max-width: $screen-lg) {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0;
    }
    
    &--mobile {
      display: none;

      @media screen and (max-width: $screen-lg) {
        display: block;
      }
    }
  }
  
  &__action {
    margin-top: 70px;
    text-align: center;

    @media screen and (max-width: $screen-lg) {
      display: none;
    }

    .button {
      min-width: 360px;
    }
  }
  
  &__footer {
    display: none;

    @media screen and (max-width: $screen-lg) {
      padding-top: 60px;
      display: block;
      text-align: center;
    }
  }
}