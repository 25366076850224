@import '../../styles/vars';

.compare {
  padding: 60px 0;

  .container {
    @media screen and (max-width: $screen-lg) {
      max-width: 100%;
    }

    @media screen and (max-width: $screen-md) {
      padding: 0;
    }
  }

  &__table {
    overflow: hidden;
    overflow-x: auto;
  }

  table {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    border-collapse: collapse;
    table-layout: fixed;

    svg {
      width: 24px;
      height: 24px;
      vertical-align: middle;

      @media screen and (max-width: $screen-md) {
        width: 20px;
        height: 20px;
      }
    }
  }

  tr {
    &:first-child {
      th {
        border-top: 0;

        &:first-child {
          border-left: 0;
          border-radius: 15px 0 0 0;

          @media screen and (max-width: $screen-md) {
            border-radius: 0;
          }
        }

        &:last-child {
          border-radius: 0 15px 0 0;
          border-right: 0;

          @media screen and (max-width: $screen-md) {
            border-radius: 0;
          }
        }
      }
    }

    &:hover {
      td {
        background-color: rgba(0, 0, 0, 0.05);
        border-top-color: transparent;
        border-bottom-color: transparent;

        @media screen and (max-width: $screen-md) {
          background-color: transparent;
        }
      }
    }
  }

  th, td {
    padding: 15px 20px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.05);
    transition: 0.25s;

    @media screen and (max-width: $screen-md) {
      padding: 10px;
      width: 130px;

      &:first-child {
        border-left: 0;
      }
    }
  }

  th {
    color: $white-color;
    background-color: $primary-color;
  }

  .compare__name {
    width: 25%;
    font-weight: 500;
    text-align: left;

    @media screen and (max-width: $screen-md) {
      position: sticky;
      left: 0;
      z-index: 2;
      width: 180px;
    }
  }

  td.compare__name {
    @media screen and (max-width: $screen-md) {
      background-color: $white-color !important;
    }
  }

  &__footer {
    margin-top: 50px;
    text-align: center;
  }
}