@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import 'vars';

* {
  box-sizing: border-box;
}

html, body {
  &.smooth-scroll {
    scroll-behavior: smooth;
  }
}

body {
  color: rgb(17, 17, 17);
  font: 400 20px/1.55 'Montserrat', Arial, sans-serif;

  @media screen and (max-width: $screen-xl) {
    font-size: 18px;
  }

  @media screen and (max-width: $screen-md) {
    font-size: 16px;
    line-height: 1.45;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;

  @media screen and (max-width: $screen-lg) {
    font-weight: 700;
  }
}

h1 {
  font-size: 92px;
  line-height: 1.23;

  @media screen and (max-width: $screen-xl) {
    font-size: 48px;
  }

  @media screen and (max-width: $screen-md) {
    font-size: 30px;
  }
}

ul, ol {
  padding-left: 22px;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a, button {
  transition: 0.3s;
}

.content {
  padding: 68px 0;

  h2, h3, h4, h5, h6, p, section {
    margin: 0 0 31px;

    @media screen and (max-width: $screen-md) {
      margin-bottom: 25px;
    }
  }

  * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  
  h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: 700;
    text-transform: uppercase;
  }

  a {
    color: #ff8562;
    
    &:hover {
      color: #b17af6;
    }
  }

  ul {
    list-style-type: none;
    counter-reset: alpha-counter;
    padding-left: 0;
    list-style-position: outside;

    li {
      counter-increment: alpha-counter;

      &::before {
        content: counter(alpha-counter, lower-alpha) ') ';
      }
    }
  }
}