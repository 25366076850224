@import '../../styles/vars';

.button {
  position: relative;
  padding: 18px 60px;
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  background-color: $secondary-color;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  border-radius: 30px;
  overflow: hidden;

  &:hover {
    background-color: darken(#00d4d4, 7%);
  }

  &--secondary {
    font-size: 14px;
    font-weight: 600;
    background-color: $primary-color;

    @media screen and (max-width: $screen-lg) {
      font-size: 16px;
    }

    &:hover {
      background-color: darken(#b17af6, 7%);
    }
  }

  &__wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transform: translateX(-85px);
    animation-name: flash;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  &__effect {
    background: linear-gradient(90deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
    width: 45px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    transform: skewX(-45deg);
  }
}

@keyframes flash {
  30% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}